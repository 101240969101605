<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 4L15 9L10 14" stroke="currentColor" stroke-width="1.5" />
    <line
      y1="-0.75"
      x2="11.25"
      y2="-0.75"
      transform="matrix(-1 0 0 1 14.25 9.75)"
      stroke="currentColor"
      stroke-width="1.5"
    />
  </svg>
</template>
